import BaseVue from '@/base/BaseVue';
import {Options} from 'vue-class-component';
import MiTitle from '@/components/title/Title.vue';
import HttpBase from '@/http/base/HttpBase';

@Options({
  components: {
    'mi-title': MiTitle
  }
})
export default class AddVip extends BaseVue {
  place: any = [];
  uploadUrl = `${HttpBase.getRootUrl()}/file/upload`;
  uploadData: any = {};
  ruleForm:any= {
  };


  submitForm(item: any) {
    this.http.addVip(this.ruleForm).then(res => {
      this.toast_success(res.msg);
      this.resetForm('refForm');
    }).catch(res => {
      this.toast_error(res.msg);
    });
  }

}
