import BaseVue from '@/base/BaseVue';
import Http from '@/http/Http';

export default class Menu extends BaseVue {
    height = window.innerHeight - 60;
    defaultActive: any = null;
    menu: any[] = [
        {
            title: '影片管理',
            item: [
                {
                    url: '/video/add',
                    title: '上传影片'
                },
                {
                    url: '/video/manager',
                    title: '影片管理'
                },
            ]
        },
        {
            title: 'VIP管理',
            item: [
                {
                    url: '/vip/manager',
                    title: 'VIP管理'
                },
                {
                    url: '/vip/order',
                    title: 'VIP订单'
                },
            ]
        },
        {
            title: '信息管理',
            item: [
                {
                    url: '/info/manager',
                    title: '影片信息管理'
                },
            ]
        },
        {
            title: '用户管理',
            item: [
                {
                    url: '/user/usermanager',
                    title: '用户列表'
                },
            ]
        },
    ];

    mounted() {

        this.$router.afterEach((to, from) => {
            this.defaultActive = to.path;
        });
    }
}
