<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style >


.el-badge__content.is-fixed.is-dot {
  border: 0 solid #FFF
}

.el-badge__content.is-fixed {
  border: 0 solid #FFF;

}

.imgError {
  color: white;
}

.styleColor1 {
  color: #FEB741;
}

.styleColor2 {
  color: #FADD3B;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#app {
  width: 100%;
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.main-view{
  margin-top: 20px;
}

.radius-view1{
  border-radius: 3px;
  background: white;
  padding: 10px 15px;
}

.el-pager{
 font-weight: normal;
}

.el-table th>.cell{
  font-weight: normal;
}


.view1{
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}


.view2{
  padding: 20px 15px;
}

.scrollView{
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollView::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}


.scrollView::-webkit-scrollbar { /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0;
  z-index: 99;
}

body::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}


body::-webkit-scrollbar { /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0;
  z-index: 99;
}

</style>
