import BaseVue from '@/base/BaseVue';
import {Options} from 'vue-class-component';

@Options({})
export default class VipOrder extends BaseVue {

  list: any = {
    list: [],
    pageNum: 0,
    pageSize: 0,
    pages: 0,
    size: 0,
    total: 0,
  };

  search: any = {
    pi: 1,
    ps: 10
  };

  mounted() {
    this.getList(1);
  }

  getList(pi: number) {
    this.search.pi = pi;
    console.log(this.search);
    this.http.getOrderList(this.search).then(res => {
      this.list = res.data;
    }).catch(res => {
      this.toast_warn(res.msg);
    });
  }

  gotoImg(img: string) {
    window.open(img)
  }


  onReset() {
    this.search = {
      pi: 1,
      ps: 10
    };
    this.getList(1);
  }

  deleted(item: any) {
    this.http.deletedVip({id: item.id}).then(res => {
      this.getList(1);
      this.toast_success(res.msg);
    }).catch(res => {
      this.toast_warn(res.msg);
    });
  }

}
