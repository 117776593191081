import BaseVue from '@/base/BaseVue';
import StorageUtil from '@/utils/StorageUtil';
import Http from '@/http/Http';

export default class Header extends BaseVue {
  height = window.innerHeight - 100;
  userInfo: any = null;

  /**
   * 退出登录
   */
  logOut() {
    StorageUtil.removeToken();
    this.$router.replace({path: '/login'});
  }

  mounted() {
    // Http.get('/user/detail', null, {
    //   success: res => {
    //     this.userInfo = res.data;
    //     if (this.userInfo.admin == 1) {
    //       this.getOtherInfo();
    //     }
    //   }
    // });
  }

  getOtherInfo() {
    // Http.get('/user/otherInfo', null, {
    //   success: res => {
    //     this.otherInfo=res.data
    //   }
    // });
  }
}
