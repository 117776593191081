import Get from '@/http/Get';
import Post from '@/http/Post';

/**
 * http请求
 */
export default class Http {
  private Get = new Get();
  private Post = new Post();

  async get(url: string, data: any | null = null) {
    return this.Get.request(url, data);
  }

  async post(url: string, data: any | null = null) {
    return this.Post.request(url, data);
  }

  async login(body: { account: string, password: string }) {
    return this.post('login/adminUser', body);
  }

  async editShop(data: any) {
    const data2 = JSON.parse(JSON.stringify(data));
    data2.imgs = data2.imgs.map((res: any) => {
      return res.url;
    });
    return this.post('/admin/editShop', data2);
  }

  async getJiFenDuiHuanList(search: any) {
    return this.get('/admin/getJiFenDuiHuanList', search);
  }


  async deletedShop(data: { id: number }) {
    return this.post('/admin/deleteShop', data);
  }

  async getShopDetail(data: { id: number }) {
    return this.get('/admin/getShopDetail', data);
  }

  async changeStatus(data: { id: number, status: number }) {
    return this.post('/admin/editShopStatus', data);
  }

  async hexiaoOrder(data: { id: number }) {
    return this.post('/admin/hexiaoOrder', data);
  }

  async shenheJifenDuiHuan(data: any) {
    return this.post('/admin/shenheJifenDuiHuan', data);
  }


  async getShopDownList() {
    return this.get('/admin/getShopDownList');
  }

  async addTask(data: any) {
    return this.post('/admin/addTask', data);
  }

  async getTaskList(search: any) {
    return this.get('/admin/getTaskList', search);
  }

  async deletedTask(data: { id: number }) {
    return this.post('/admin/deleteTask', data);
  }

  async getTaskListById(data: { id: number }) {
    return this.get('/admin/getTaskListById', data);
  }

  async getAppUserList(search: any) {
    return this.get('/admin/getUserList', search);
  }

  async deletedUser(param: { id: any }) {
    return this.post('/admin/deleteUser', param);
  }

  async addUser(ruleForm: { password: string; name: string; account: string }) {
    return this.post('/admin/addUser', ruleForm);
  }

  async getImageQiNiuUploadToken() {
    return this.get('/file/imgToken', null,);
  }

  async setSystemInfo(ruleForm: { jifen: number }) {
    return this.post('/admin/setSystemInfo', ruleForm);
  }

  async getSystemInfo() {
    return this.get('/admin/getSystemInfo');
  }

  async getInfoList(search: any) {
    return this.get('/admin/info/getInfoList', search);
  }

  async addInfoList(data: any) {
    return this.post('/admin/info/addInfo', data);
  }

  async infoDisable(data: any) {
    return this.post('/admin/info/disable', data);
  }

  async infoEnable(data: any) {
    return this.post('/admin/info/enable', data);
  }

  async getInfoDetail(data: any) {
    return this.get('/admin/info/getInfoDetail', data);
  }

  async editInfo(data: any) {
    return this.post('/admin/info/editInfo', data);
  }

  async getInfoOptions(data: { type: number }) {
    return this.get('/admin/info/getInfoOptions', data);
  }

  async deleteVideoFile(data: { key: string[] }) {
    return this.post('/admin/video/deleteVideoFile', data);
  }

  async addVideo(data: any) {
    return this.post('/admin/video/addVideo', data);
  }


  async editVideo(data: any) {
    return this.post('/admin/video/editVideo', data);
  }

  async getVideoList(search:any) {
    return this.get('/admin/video/getVideoList', search);
  }

  async changeDisabled(data: { id: number,disabled:number }) {
    return this.post('/admin/video/changeDisabled', data);
  }

  async getVideoDetail(data: { id: number }) {
    return this.get('/admin/video/getVideoDetail', data);
  }

  async addVip(data: any) {
    return this.post('/admin/vip/add', data);
  }


  async deletedVip(data: any) {
    return this.post('/admin/vip/deleted', data);
  }

  async getVipList(search: any) {
    return this.get('/admin/vip/getVipList', search);
  }

  async getOrderList(search: any) {
    return this.get('/admin/vip/getOrderList', search);
  }

  async getVipDetail(data: any) {
    return this.get('/admin/vip/getVipDetail', data);
  }

  async editVip(data: any) {
    return this.post('/admin/vip/editVip', data);
  }


}
