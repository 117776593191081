import BaseVue from '@/base/BaseVue';
import Http from '@/http/Http';
import StorageUtil from '@/utils/StorageUtil';
import * as http from "http";

/**
 * 登录页面
 */
export default class Login extends BaseVue {
    loginData: any = {
        account: '',
        password: '',
    };
    isLogin = false;

    /**
     * 登录
     */
    login() {
        this.isLogin = true;
        this.http.login(this.loginData).then(res => {
            StorageUtil.token = res.data.token;
            this.toast_success(res.msg);
            this.$router.push({path: '/', name: 'home'});
            this.isLogin = false
        }).catch(res => {
            this.toast_warn(res.msg, true);
            this.isLogin = false
        })
    }

}
