import BaseVue from '@/base/BaseVue';
import {Options} from 'vue-class-component';
import MiTitle from '@/components/title/Title.vue';
import HttpBase from '@/http/base/HttpBase';
import {ElLoading} from "element-plus";
import * as qiniu from 'qiniu-js'

@Options({
    components: {
        'mi-title': MiTitle
    }
})
export default class VideoEdit extends BaseVue {
    place: any = [];
    uploadUrl = HttpBase.QiNiu_URL;
    uploadData: any = {};
    uploadData2: any = {};
    uploadManyData: any = {};
    ruleForm: any = {
        host: 0,
        vip: 1,
        disabled: 0,
        type: 0,
        playCount: 0
    };
    rules: any = {};

    typeList: any = [];
    chubanList: any = [];
    shijianList: any = [];
    daoyanList: any = [];
    yanyuanList: any = [];

    videoEpisodesList: any[] = []

    removeKeys: any = []

    mounted() {
        this.http.getVideoDetail({id: this.getParameters().id}).then(res => {
            res.data.performer = res.data.performer.split(",")
            this.ruleForm = res.data
            this.videoEpisodesList = this.ruleForm.videoList
            this.videoEpisodesList.forEach(item => {
                item.status = 2
                item.key = item.url
            })
            this.ruleForm.videoList = null
        })
        this.http.getInfoOptions({type: 1}).then(res => {
            this.typeList = res.data.list || [];
        });

        this.http.getInfoOptions({type: 2}).then(res => {
            this.chubanList = res.data.list || [];
        });

        this.http.getInfoOptions({type: 3}).then(res => {
            this.shijianList = res.data.list || [];
        });

        this.http.getInfoOptions({type: 4}).then(res => {
            this.daoyanList = res.data.list || [];
        });

        this.http.getInfoOptions({type: 5}).then(res => {
            this.yanyuanList = res.data.list || [];
        });
    }

    changeSort() {
        this.videoEpisodesList = this.videoEpisodesList.sort((a: any, b: any) => {
            return a.sorts - b.sorts
        })
    }


    async beforeImageUpload(rawFile: any) {
        if (rawFile.size / 1024 / 1024 > 10) {
            this.toast_warn('单张图片大小不能超过10MB!');
            return false;
        }
        const data = await this.http.getImageQiNiuUploadToken();
        this.uploadData = data.data;
        return true;
    }

    // 上传成功，获取返回的图片地址
    handleUpImage(res: any) {
        this.ruleForm.img1 = this.uploadData.prefix + res.key;
    }

    async beforeImageUpload2(rawFile: any) {
        if (rawFile.size / 1024 / 1024 > 10) {
            this.toast_warn('单张图片大小不能超过10MB!');
            return false;
        }
        const data = await this.http.getImageQiNiuUploadToken();
        this.uploadData2 = data.data;
        return true;
    }

    // 上传成功，获取返回的图片地址
    handleUpImage2(res: any) {
        this.ruleForm.img2 = this.uploadData2.prefix + res.key;
    }

    openUlr(url: string) {
        window.open(url)
    }

    removeVideo(index: number) {

        const item = this.videoEpisodesList[index]

        this.videoEpisodesList.splice(index, 1)
        this.removeKeys.push(item.key)

    }

    removeVideoAll() {

        this.removeKeys.push(...this.videoEpisodesList.map(res => res.key))
        this.videoEpisodesList.splice(0, this.videoEpisodesList.length)
    }


    errorVideoUploadMany(rawFile: any, res2: any) {
        const files = this.videoEpisodesList.filter(item => {
            return item.uid == res2.uid
        })
        files.forEach(item => {
            this.toast_warn(item.name + "   上传失败")
            this.videoEpisodesList.splice(this.videoEpisodesList.indexOf(item), 1)
        })
    }

    async beforeVideoUploadMany(rawFile: any, index: number) {
        // if(!rawFile.type.startsWith("video")){
        //     this.toast_warn('只能上传视频')
        //     return false
        // }
        const data = await this.http.getImageQiNiuUploadToken();
        this.videoEpisodesList.push({
            name: rawFile.name.substring(0, rawFile.name.lastIndexOf('.') == -1 ? rawFile.name.length : rawFile.name.lastIndexOf('.')),
            uid: rawFile.uid,
            sorts: this.videoEpisodesList.length + 1,
            vip: 1,
            disabled: 1,
            progress: 0,
            status: 0,
            key: data.data.key,
            url: `${data.data.prefix}/${data.data.key}`
        })
        this.uploadManyData = data.data;
        this.uploadManyData.fsizeLimit = 1024 * 1024 * 1024 * 10
        return true;
    }

    // 上传成功，获取返回的图片地址
    handleUpVideoMany(res: any, res2: any) {
        const files = this.videoEpisodesList.filter(item => {
            return item.uid == res2.uid
        })
        if (files.length > 0) {
            files[0].progress = 100
            files[0].status = 2
        }
    }


    handleProgressVideoMany(res: any, res2: any) {
        const files = this.videoEpisodesList.filter(item => {
            return item.uid == res2.uid
        })
        if (files.length > 0) {
            files[0].progress = res2.percentage.toFixed(2)
            files[0].status = 1
            console.log(res2)
        }
    }


    uploadFileMany(res: any) {
        return new Promise((resolve, reject) => {
            console.log('res')
            console.log(res)
            const files = this.videoEpisodesList.filter(item => {
                return item.uid == res.file.uid
            })

            const observable = qiniu.upload(res.file, res.data.key, res.data.token, {}, {}).subscribe({
                next: (res2: any) => {

                    if (files.length > 0) {
                        files[0].progress = res2.total.percent.toFixed(2)
                        files[0].status = 1
                    }
                },
                error: (res: any) => {
                    reject(res)
                },
                complete: (res: any) => {
                    resolve(res)
                }
            })
        })
    }

    submitForm(item: any) {
        if (this.videoEpisodesList.filter(res => res.status !== 2).length > 0) {
            this.toast_error('请等待全部视频上传完毕')
            return
        }
        const data = JSON.parse(JSON.stringify(this.ruleForm))
        if (data.performer) {
            data.performer = data.performer.join(",")
        }
        let loading = ElLoading.service({
            fullscreen: true,
            text: "编辑中..."
        })
        this.http.editVideo({...data, videoList: this.videoEpisodesList}).then(res => {
            this.toast_success(res.msg);
            loading.close()
            if (this.removeKeys.length == 0) {
                return
            }
            loading = ElLoading.service({
                fullscreen: true,
                text: "删除视频资源中..."
            })
            this.http.deleteVideoFile({key: this.removeKeys}).then(res => {
                loading.close()
                this.toast_success('删除成功')
            }).catch(res => {

                loading.close()
                this.toast_warn('删除失败，请重试')
            })
        }).catch(res => {
            loading.close()
            this.toast_error(res.msg);
        });
    }

}
