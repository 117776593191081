// @ts-ignore
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import StorageUtil from '@/utils/StorageUtil';
import isDebug from '../../main';
import {ElMessage} from 'element-plus';
import ResponseModel from "@/http/ResponseModel";

/**
 * http请求的基类
 */

export default abstract class HttpBase {
    static Debug_APP_URL = 'https://app.bjmengtu.cn/';
    static QiNiu_URL = 'https://up-z2.qiniup.com';
    // static Debug_APP_URL = 'https://api.frp.mish.top/';
    static UnDebug_APP_URL = '';

    http: AxiosInstance;

    public static getRootUrl(): string {
        return isDebug ? HttpBase.Debug_APP_URL : HttpBase.UnDebug_APP_URL;
    }

    constructor() {
        console.log(isDebug, HttpBase.getRootUrl());
        this.http = this.createAxiosInstance();
        this.setHttpFilter();
    }


    /**
     * 设置默认请求数据
     */
    createAxiosInstance(): AxiosInstance {
        return Axios.create({
            baseURL: HttpBase.getRootUrl(),
            timeout: 10000,
            headers: {
                token: StorageUtil.token,
                'Content-Type': 'application/json;charset=UTF-8',
            }
        });
    }

    /**
     * 设置过滤器
     */
    setHttpFilter() {
        this.http.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            (error: any) => {
                return Promise.reject(error);
            }
        );
    }

    /**
     * 默认处理then的处理
     * @param res
     * @param resolve
     * @param reject
     */
    protected defaultThen(res: AxiosResponse, resolve: Function, reject: Function) {
        const responseModel = new ResponseModel();
        responseModel.code = res.data.code;
        responseModel.msg = res.data.msg;
        responseModel.data = res.data.data;
        if (responseModel.code === 1) {
            resolve(responseModel)
        } else {
            reject(responseModel)
        }
    }

    /**
     * 默认请求进入catch的处理
     * @param error
     * @param reject
     * @protected
     */
    protected defaultCatch(error: any, reject: Function) {
        const res = error.response;
        switch (res ? res.status : 0) {
            case 401:
                ElMessage.error({
                    message: '身份信息过期，请重新登录',
                    type: 'error'
                });
                StorageUtil.removeToken();
                location.href = './login';
                return;
            case 403:
                ElMessage.error({
                    message: '没有访问权限',
                    type: 'error'
                });
                break;
            default: // 一般就是网络错误
                ElMessage.error({
                    message: error.message,
                    type: 'error'
                });
        }
        let responseModel = new ResponseModel()
        responseModel.code = 0
        responseModel.msg = error.message
        reject(responseModel)
    }

    /**
     * 开始请求
     */
    abstract request(url: string, dy: any | null): Promise<ResponseModel>
}
