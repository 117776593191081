/**
 * vue页面的基类
 */
import {Vue} from 'vue-class-component';
import {ElMessage} from 'element-plus';
import DateUtil from '@/utils/DateUtil';
import {RouteLocationRaw} from "vue-router";
import Http from "@/http/Http";

abstract class BaseVue extends Vue {
    protected http = new Http()

    getParameters(): any {
        return this.$route.query;
    }

    getViewHeight(...viewName: string[]): number {
        let height = 0
        viewName.forEach(item => {
            let view = this.$refs[item] as any
            if (view != null) {
                height += view.$el.offsetHeight
            }
        });
        return height;
    }


    mounted() {

    }

    back() {
        this.$router.go(-1);
    }

    goto(any: RouteLocationRaw) {
        this.$router.push(any);
    }

    /**
     * 重置表单
     * @param formName
     */
    resetForm(formName: any) {
        // @ts-ignore
        this.$refs[formName].resetFields();
    }

    /**
     * 消息提示
     * @param message 消息
     * @param type 类型
     */
    toast(message: any, canClose: boolean = false) {
        ElMessage({
            message: message,
            showClose: canClose
        });
    }

    /**
     * 消息提示-成功
     * @param msg
     * * @param canClose
     */
    toast_success(msg: any, canClose: boolean = false) {
        ElMessage.success({
            message: msg,
            type: 'success',
            showClose: canClose
        });
    }

    /**
     * 消息提示-警告
     * @param msg
     * * @param canClose
     */
    toast_warn(msg: any, canClose: boolean = false) {
        ElMessage.warning({
            message: msg,
            type: 'warning',
            showClose: canClose
        });
    }

    /**
     * 消息提示-警告
     * @param msg
     * @param canClose
     */
    toast_error(msg: any, canClose: boolean = false) {
        ElMessage.error({
            message: msg,
            showClose: canClose,
            type: 'error'
        });
    }

    dateFormat(time: any, format: any) {
        return DateUtil.dateFormat(time, format)
    }
}

export default BaseVue;
