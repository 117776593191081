import BaseVue from '@/base/BaseVue';
import {Options} from 'vue-class-component';
import MiTitle from '@/components/title/Title.vue';
import HttpBase from '@/http/base/HttpBase';

@Options({
    components: {
        'mi-title': MiTitle
    }
})
export default class EditVip extends BaseVue {
    place: any = [];
    uploadUrl = `${HttpBase.getRootUrl()}/file/upload`;
    uploadData: any = {};
    ruleForm: any = {};

    mounted() {
        this.http.getVipDetail({id: this.getParameters().id}).then(res => {
            this.ruleForm = res.data;
        }).catch(res => {
            this.toast_error(res.msg);
        });
    }

    submitForm(item: any) {
        this.http.editVip(this.ruleForm).then(res => {
            this.toast_success(res.msg);
            this.back();
        }).catch(res => {
            this.toast_error(res.msg);
        });
    }

}
