import BaseVue from '@/base/BaseVue';
import {Options} from 'vue-class-component';

@Options({})
export default class InfoManager extends BaseVue {

  list: any = {
    list: [],
    pageNum: 0,
    pageSize: 0,
    pages: 0,
    size: 0,
    total: 0,
  };

  search: any = {
    pi: 1,
    ps: 10
  };

  mounted() {
    this.getList(1);
  }

  gotoImg(img: string) {
    window.open(img)
  }

  getList(pi: number) {
    this.search.pi = pi;
    console.log(this.search);
    this.http.getInfoList(this.search).then(res => {
      this.list = res.data;
    }).catch(res => {
      this.toast_warn(res.msg);
    });
  }

  onReset() {
    this.search = {
      name: null,
      pi: 1,
      ps: 10
    };
    this.getList(1);
  }

  deleted(item: any) {
    this.http.infoDisable({id: item.id}).then(res => {
      this.getList(1);
      this.toast_success(res.msg);
    }).catch(res => {
      this.toast_warn(res.msg);
    });
  }

  enable(item:any){
    this.http.infoEnable({id: item.id}).then(res => {
      this.getList(1);
      this.toast_success(res.msg);
    }).catch(res => {
      this.toast_warn(res.msg);
    });
  }

  changeStatus(item: any,status:number) {
    this.http.changeStatus({id: item.id,status:status}).then(res => {
      this.getList(1);
      this.toast_success(res.msg);
    }).catch(res => {
      this.toast_warn(res.msg);
    });
  }
}
