/**
 * storage存储管理类
 */
export default class StorageUtil {

  /**
   * token
   */
  public static get token() {
    return localStorage.getItem('token');
  }

  public static set token(toke: string | null) {
    localStorage.setItem('token', toke ? toke : '');
  }

  public static removeToken() {
    localStorage.removeItem('token');
  }
}
