
import {Options, Vue} from 'vue-class-component';
import Menu from '@/components/menu/Menu.vue';
import Header from '@/components/header/Header.vue';
import BaseVue from '@/base/BaseVue';

@Options({
  components: {
    Menu: Menu,
    Header: Header
  }
})
export default class Home extends BaseVue {
  height=0

  mounted() {
    this.changeHeight()
    window.onresize = () => {
      this.changeHeight()
    };

    this.$router.push({path: '/index', name: 'index'});
  }
  changeHeight(){
    this.height=document.body.clientHeight-this.getViewHeight("head")
  }
}
