import BaseVue from '@/base/BaseVue';
import {Options} from 'vue-class-component';
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
@Options({

})
export default class Fufei extends BaseVue {
    mode= 'default'
    editor= null
    toolbarConfig = {}
    editorConfig = { placeholder: '请输入内容...' }
    valueHtml = '<p>hello</p>'
    create(editor:any) {
        alert(1)
        this.editor = Object.seal(editor)
    }

    beforeCreate(){

    }
}
