import {createApp} from 'vue';
import App from './App.vue';
import ElementPlus, {ElIcon} from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import router from '@/router/router';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
const isDebug = true;

const app = createApp(App);
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(router);

app.mount('#app');

export default isDebug
