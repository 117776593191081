import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import Home from '../views/Home.vue';
import Login from '@/views/login/Login.vue';
import Index from '@/views/index/Index.vue';
import {ElMessage} from 'element-plus';
import StorageUtil from '@/utils/StorageUtil';
import Page404 from '@/views/404/Index.vue';
import UserManager from '@/views/user/usermanager/index.vue';
import Order from '@/views/order/usermanager/index.vue';
import System from '@/views/system/add.vue';
import InfoManager from "@/views/info/infomamanger/index.vue";
import InfoAdd from "@/views/info/infomamanger/add/add.vue";
import EditInfo from "@/views/info/infomamanger/edit/add.vue";
import VideoAdd from "@/views/video/videomanager/add/add.vue";
import VideoEdit from "@/views/video/videomanager/edit/add.vue";
import VideoManager from "@/views/video/videomanager/index.vue";
import VipManager from "@/views/vip/index.vue";
import AddVip from "@/views/vip/add/add.vue";
import EditVip from "@/views/vip/edit/edit.vue";
import Fufei from "@/views/xieyi/fufei/index.vue";
import VipOrder from "@/views/viporder/index.vue";


const routers: RouteRecordRaw[] = [ // 二级路由页面
    {
        path: '/user/usermanager',
        name: 'usermanager',
        component: UserManager,
        meta: {
            title: '用户管理',
        },
    },
    {
        path: '/vip/manager',
        name: 'vipmanager',
        component: VipManager,
        meta: {
            title: 'VIP管理',
        },
    },
    {
        path: '/xieyi/fufei',
        name: 'xieyifufei',
        component: Fufei,
        meta: {
            title: '付费协议',
        },
    },
    {
        path: '/vip/add',
        name: 'vipadd',
        component: AddVip,
        meta: {
            title: '添加VIP信息',
        },
    },
    {
        path: '/vip/order',
        name: 'viporder',
        component: VipOrder,
        meta: {
            title: 'VIP订单管理',
        },
    },
    {
        path: '/vip/edit',
        name: 'vipedit',
        component: EditVip,
        meta: {
            title: '编辑VIP信息',
        },
    },
    {
        path: '/info/manager',
        name: 'infomanager',
        component: InfoManager,
        meta: {
            title: '影片信息管理',
        },
    },
    {
        path: '/info/add',
        name: 'infoadd',
        component: InfoAdd,
        meta: {
            title: '添加影片信息',
        },
    },
    {
        path: '/info/edit',
        name: 'infoedit',
        component: EditInfo,
        meta: {
            title: '编辑影片信息',
        },
    },
    {
        path: '/video/add',
        name: 'videoadd',
        component: VideoAdd,
        meta: {
            title: '添加影片',
        },
    },
    {
        path: '/video/edit',
        name: 'videoedit',
        component: VideoEdit,
        meta: {
            title: '修改影片',
        },
    },

    {
        path: '/video/manager',
        name: 'videomanager',
        component: VideoManager,
        meta: {
            title: '影片管理',
        },
    },
    {
        path: '/system/manager',
        name: 'systemmanager',
        component: System,
        meta: {
            title: '系统配置',
        },
    },

    {
        path: '/index',
        name: 'index',
        component: Index,
        meta: {
            title: '首页',
        },
    },
    {
        path: '/404',
        name: '404',
        component: Page404,
        meta: {
            title: '页面丢失了',
        }
    }
];


const routes: RouteRecordRaw[] = [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: '首页',
            },
            children: routers
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                title: '登录',
            },
        },
    ]
;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

const isInRouter = (path: string, route: RouteRecordRaw[]) => {
    if (path == '/') {
        return false;
    }
    let is404 = true;
    route.forEach(item => {
        if (!item.children) {
            if (item.path == path) {
                is404 = false;
            }
        } else {
            is404 = isInRouter(path, item.children!!);
        }
        if (!is404) {
            return;
        }
    });
    return is404;
};

router.beforeEach((to, from, next) => {
    if (isInRouter(to.path, routes)) {
        return next('/404');
    }

    if (!StorageUtil.token) {
        if (to.path !== '/login') {
            ElMessage.warning({
                message: '请先登录哦',
                showClose: true,
                type: 'warning'
            });
            return next('/login');
        }
    }
    if (to.meta.title) {
        const title: any = to.meta.title;
        document.title = '管理后台-' + title;
    }
    if (to.path == '/index' && from.path != '/') {
        return next(from);
    }
    next();
});

export default router;
