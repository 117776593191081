import HttpBase from '@/http/base/HttpBase';
import {AxiosResponse} from 'axios';
import ResponseModel from "@/http/ResponseModel";

/**
 * post请求
 */
export default class Post extends HttpBase {

    async request(url: string, body: any | null = null): Promise<ResponseModel> {
        return new Promise<ResponseModel>((resolve, reject) => {
            this.http.post(url, body)
                .then((res: AxiosResponse) => {
                    this.defaultThen(res, resolve, reject)
                }).catch((res: any) => {
                this.defaultCatch(res, reject)
            });
        })
    }
}
