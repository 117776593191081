import BaseVue from '@/base/BaseVue';
import {Options} from 'vue-class-component';
import MiTitle from '@/components/title/Title.vue';
import HttpBase from '@/http/base/HttpBase';

@Options({
  components: {
    'mi-title': MiTitle
  }
})
export default class System extends BaseVue {
  place: any = [];
  uploadUrl = `${HttpBase.getRootUrl()}/file/upload`;
  uploadData: any = {};
  ruleForm = {
    jifen: 0,

  };

  mounted() {
   this.getJiFen()
  }

  getJiFen(){
    this.http.getSystemInfo().then(res => {
      this.ruleForm = res.data;
    });
  }

  submitForm() {
    this.http.setSystemInfo(this.ruleForm).then(res => {
      this.toast_success(res.msg);
      this.getJiFen()
    }).catch(res => {
      this.toast_error(res.msg);
    });
  }

}
