import BaseVue from '@/base/BaseVue';
import {Options} from 'vue-class-component';
import * as url from 'url';

@Options({})
export default class VideoManager extends BaseVue {

    list: any = {
        list: [],
        pageNum: 0,
        pageSize: 0,
        pages: 0,
        size: 0,
        total: 0,
    };

    typeList: any = [];
    chubanList: any = [];
    shijianList: any = [];
    daoyanList: any = [];
    yanyuanList: any = [];

    search: any = {
        pi: 1,
        ps: 10
    };

    mounted() {
        this.getList(1);
        this.http.getInfoOptions({type: 1}).then(res => {
            this.typeList = res.data.list || [];
        });

        this.http.getInfoOptions({type: 2}).then(res => {
            this.chubanList = res.data.list || [];
        });

        this.http.getInfoOptions({type: 3}).then(res => {
            this.shijianList = res.data.list || [];
        });

        this.http.getInfoOptions({type: 4}).then(res => {
            this.daoyanList = res.data.list || [];
        });

        this.http.getInfoOptions({type: 5}).then(res => {
            this.yanyuanList = res.data.list || [];
        });
    }

    gotoImg(img: string) {
        window.open(img)
    }

    getList(pi: number) {
        this.search.pi = pi;
        const data = JSON.parse(JSON.stringify(this.search))
        if (data.createStartTime) {
            data.createStartTime = new Date(data.createStartTime).getTime()
        }

        if (data.createEndTime) {
            data.createEndTime = new Date(data.createEndTime).getTime()+(1000*60*60*24)
        }
        this.http.getVideoList(data).then(res => {
            this.list = res.data;
        }).catch(res => {
            this.toast_warn(res.msg);
        });
    }

    onReset() {
        this.search = {
            name: null,
            pi: 1,
            ps: 10
        };
        this.getList(1);
    }

    deleted(item: any, disabled: number) {
        this.http.changeDisabled({id: item.id, disabled: disabled}).then(res => {
            this.toast_success(res.msg);
            this.getList(this.search.pi)
        }).catch(res => {
            this.toast_warn(res.msg);
        });
    }
}
